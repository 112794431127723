exports.components = {
  "component---src-containers-post-tsx": () => import("./../../../src/containers/Post.tsx" /* webpackChunkName: "component---src-containers-post-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-advisory-services-irp-tabletop-tsx": () => import("./../../../src/pages/advisory-services/irp-tabletop.tsx" /* webpackChunkName: "component---src-pages-advisory-services-irp-tabletop-tsx" */),
  "component---src-pages-advisory-services-irp-tsx": () => import("./../../../src/pages/advisory-services/irp.tsx" /* webpackChunkName: "component---src-pages-advisory-services-irp-tsx" */),
  "component---src-pages-advisory-services-vciso-tsx": () => import("./../../../src/pages/advisory-services/vciso.tsx" /* webpackChunkName: "component---src-pages-advisory-services-vciso-tsx" */),
  "component---src-pages-assessment-services-ai-management-tsx": () => import("./../../../src/pages/assessment-services/ai-management.tsx" /* webpackChunkName: "component---src-pages-assessment-services-ai-management-tsx" */),
  "component---src-pages-assessment-services-cis-gap-tsx": () => import("./../../../src/pages/assessment-services/cis-gap.tsx" /* webpackChunkName: "component---src-pages-assessment-services-cis-gap-tsx" */),
  "component---src-pages-assessment-services-iso-27001-tsx": () => import("./../../../src/pages/assessment-services/iso-27001.tsx" /* webpackChunkName: "component---src-pages-assessment-services-iso-27001-tsx" */),
  "component---src-pages-assessment-services-nist-800-53-tsx": () => import("./../../../src/pages/assessment-services/nist-800-53.tsx" /* webpackChunkName: "component---src-pages-assessment-services-nist-800-53-tsx" */),
  "component---src-pages-assessment-services-nist-csf-tsx": () => import("./../../../src/pages/assessment-services/nist-csf.tsx" /* webpackChunkName: "component---src-pages-assessment-services-nist-csf-tsx" */),
  "component---src-pages-assessment-services-privacy-tsx": () => import("./../../../src/pages/assessment-services/privacy.tsx" /* webpackChunkName: "component---src-pages-assessment-services-privacy-tsx" */),
  "component---src-pages-assessment-services-risk-assessment-tsx": () => import("./../../../src/pages/assessment-services/risk-assessment.tsx" /* webpackChunkName: "component---src-pages-assessment-services-risk-assessment-tsx" */),
  "component---src-pages-assessment-services-risk-management-tsx": () => import("./../../../src/pages/assessment-services/risk-management.tsx" /* webpackChunkName: "component---src-pages-assessment-services-risk-management-tsx" */),
  "component---src-pages-assessment-services-soc-2-tsx": () => import("./../../../src/pages/assessment-services/soc-2.tsx" /* webpackChunkName: "component---src-pages-assessment-services-soc-2-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-cybermonth-tsx": () => import("./../../../src/pages/cybermonth.tsx" /* webpackChunkName: "component---src-pages-cybermonth-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-managed-phishing-simulations-tsx": () => import("./../../../src/pages/managed-phishing-simulations.tsx" /* webpackChunkName: "component---src-pages-managed-phishing-simulations-tsx" */),
  "component---src-pages-managed-services-tsx": () => import("./../../../src/pages/managed-services.tsx" /* webpackChunkName: "component---src-pages-managed-services-tsx" */),
  "component---src-pages-managed-vulnerability-assessments-tsx": () => import("./../../../src/pages/managed-vulnerability-assessments.tsx" /* webpackChunkName: "component---src-pages-managed-vulnerability-assessments-tsx" */),
  "component---src-pages-online-security-training-tsx": () => import("./../../../src/pages/online-security-training.tsx" /* webpackChunkName: "component---src-pages-online-security-training-tsx" */),
  "component---src-pages-partner-programs-tsx": () => import("./../../../src/pages/partner-programs.tsx" /* webpackChunkName: "component---src-pages-partner-programs-tsx" */),
  "component---src-pages-penetration-testing-tsx": () => import("./../../../src/pages/penetration-testing.tsx" /* webpackChunkName: "component---src-pages-penetration-testing-tsx" */),
  "component---src-pages-podcasts-index-tsx": () => import("./../../../src/pages/podcasts/index.tsx" /* webpackChunkName: "component---src-pages-podcasts-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-pro-services-tsx": () => import("./../../../src/pages/pro-services.tsx" /* webpackChunkName: "component---src-pages-pro-services-tsx" */),
  "component---src-pages-security-audits-tsx": () => import("./../../../src/pages/security-audits.tsx" /* webpackChunkName: "component---src-pages-security-audits-tsx" */),
  "component---src-pages-security-statistics-tsx": () => import("./../../../src/pages/security-statistics.tsx" /* webpackChunkName: "component---src-pages-security-statistics-tsx" */),
  "component---src-pages-security-training-tsx": () => import("./../../../src/pages/security-training.tsx" /* webpackChunkName: "component---src-pages-security-training-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */),
  "component---src-templates-podcast-list-by-category-tsx": () => import("./../../../src/templates/PodcastListByCategory.tsx" /* webpackChunkName: "component---src-templates-podcast-list-by-category-tsx" */),
  "component---src-templates-video-list-by-category-tsx": () => import("./../../../src/templates/VideoListByCategory.tsx" /* webpackChunkName: "component---src-templates-video-list-by-category-tsx" */),
  "component---src-templates-video-list-tsx": () => import("./../../../src/templates/VideoList.tsx" /* webpackChunkName: "component---src-templates-video-list-tsx" */)
}

